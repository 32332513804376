.custom-tooltip {
	background-color: rgba(162, 155, 254,0.3);
	font-size: 13px;
	padding: 3px;
	border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	color: #341f97;
	font-weight:600
}

.custom-tooltip.label {
	margin-top: 0px;
	padding: 0;
}