:root {
  --app-height: 100vh;
}
html {
  background-color:#D0BFF3
}
body{
  margin:0
}
#root {

  height:100%;
}

.scroller {
  height: 100%;
  overflow-y: scroll;
}
.scroller{
    scrollbar-face-color: #FFFFFF;
    scrollbar-shadow-color: #FFFFFF;
    scrollbar-highlight-color: #FFFFFF;
    scrollbar-3dlight-color: #FFFFFF;
    scrollbar-darkshadow-color: #FFFFFF;
    scrollbar-track-color: #FFFFFF;
    scrollbar-arrow-color: #FFFFFF;
}

/* Let's get this party started */
.scroller::-webkit-scrollbar {
    width: 5px;
}
 
/* Track */
.scroller::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
.scroller::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(95, 39, 205,0.4); 
    
}

.clickable {
  cursor:pointer;
}

.clickable:hover {
  background-color:rgba(162, 155, 254, 0.12);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPaper-root {
}

.is-active-nav {
  background-color: rgba(162, 155, 254,0.4);
  
}

.is-active-nav p {

  color: #5f27cd !important;
  font-weight: 600;
}

.inactive:hover {
  background-color:rgba(162, 155, 254, 0.2);;
}

.copilot-items-enter {
  opacity: 0;
}
.copilot-items-enter-active {
  opacity: 1;
  animation: pop 0.3s linear 1;
}
.copilot-items-exit {
  opacity: 1;
}
.copilot-items-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

@keyframes pop{
  25%  {transform: scale(1.1);}
}

.hideUntilHover {
  transition: opacity 0.35s;
  opacity: 0;
}

.showHiddenHovers:hover .hideUntilHover,
.hideUntilHover:hover {
  transition: opacity 0.35s;
  opacity: 1;
}