 button {
 	background: 0 0;
    padding: 0;
    border-radius:  0;
	cursor: pointer;
 }

.ais-SearchBox {
	padding: 10px;
}
.ais-SearchBox-form {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	height: 40px;
	padding: 10px 20px 10px 40px;
	background-color: white;
	border: solid 1px #E0E0E0;
	border-radius: 50px;
	font-size: 12px;

}

.ais-SearchBox-input {
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	font-family: Hind,system-ui,BlinkMacSystemFont,-apple-system,sans-serif;
	font-size: 14px;
	color: #5d6494;
	outline: 0;
	border: none;
}

.ais-SearchBox-submit, .ais-SearchBox-reset {
	display: none;
}


.ais-CurrentRefinements-list {
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: -2px;
}

.ais-CurrentRefinements-item {
	display: block;
    margin: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #848ab8;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
}
.ais-CurrentRefinements-delete {
	color: #fff;
    opacity: .8;
        border: none;
	margin-left: 2px;
	padding-left: 2px;
	padding-right: 2px;
}

.ais-CurrentRefinements-category{
	margin-left: 2px;
}




.filterSectionHeader, .filterHeader {
    color: #7E57C2;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.filterSectionHeader {
    color:#512DA8;
    font-size: 15px;
    text-transform: initial;
    margin-top: 0;
}

.ais-RefinementList, .ais-NumericMenu {
	font-size: 12px;
	color: #3a416f;
	margin-bottom: 15px;

}
.ais-RefinementList-list, .ais-NumericMenu-list {
	list-style: none;
	padding: 0;
	margin-bottom: 5px;
    margin-top:5px
}

.ais-RefinementList-item--selected{
	font-weight: 700;
}

.ais-RefinementList-item, .ais-NumericMenu-item {
	margin-bottom: 2px;
}

.ais-RefinementList-labelText, .ais-NumericMenu-labelText {
	margin-left: 5px;
    font-size:12px
}

.ais-RefinementList-count{
	display: inline-block;
	background-color: rgba(197,201,224,.2);
	color: #848ab8;
	border-radius: 4px;
	margin-left: 8px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 10px;
}

.ais-RefinementList-showMore, .ais-ClearRefinements-button {
	font-size: 10px;
	background-color: rgba(197,201,224,.2);
	color: #848ab8;
	opacity: 0.8;
    padding: 5px;
    border-radius: 4px;
    font-weight: 600;
        border: none;
	letter-spacing: 1.2px;
}

.ais-ClearRefinements-button {
	opacity: 1;
	font-size: 12px;
}



.ais-Pagination{
	padding-top: 5px;
}

.ais-Pagination-list {
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: -2px;
    margin-bottom: -2px;
    justify-content: center;
}

.ais-Pagination-item{
	display: block;
    margin: 2px;
    color: #21243d;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 2px 3px 0 rgb(93 100 148 / 20%);
    box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 2px 3px 0 rgb(93 100 148 / 20%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}
.ais-Pagination-link{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    color: inherit;
    width: 32px;
    height: 32px;
    text-decoration: none;
    font-size: 12px;
}

.ais-Pagination-item--selected{
	background-color: #5f27cd;
    color: #fff;
}